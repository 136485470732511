import React from 'react';
import TopBar from '../components/TopBar';
import NavBar from '../components/NavBar';
import Hero from '../components/Hero';
import Services from '../components/Services';
import About from '../components/About';
import Testimonial from '../components/testimonial';
import Footer from '../components/footer';
import Hero2 from '../components/Hero2';

function Home() {
  return (
    <div >
      <TopBar />
      <NavBar />
      <Hero />
      <Services />
      <About />
      <Testimonial />
      <Hero2 />
      <Footer />
    </div>
  );
}

export default Home;