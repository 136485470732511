import React from 'react'
import {AiFillPhone, AiOutlineClockCircle} from 'react-icons/ai'
import logo from '../assets/logo-no-text.png' 

const TopBar = () => {
  return (
    <div className='flex justify-between items-center px-4 py-2'>
        <div className='flex items-center'>
            <img src={logo} alt='logo' className="w-12 md:w-24 mr-6"/>
            <h1 className='md:text-2xl text-xl font-bold text-gray-700'>Fencing & Tree Work</h1>
        </div>
        <div className='flex'>
            <div className='md:flex items-center px-6'>
                <a href="tel:01292821792" class="flex items-center">
                    <AiFillPhone size="20" class="mr-2 text-[var(--primary-dark)]" />
                    <p class="hidden md:block md:text-lg text-sm text-gray-700">07810 876930</p>
                </a>

            </div>
            <div className='hidden md:flex items-center px-6'>
            <a href="tel:01292821792" class="flex items-center">
                <AiOutlineClockCircle size={20} className='mr-2 text-[var([--primary-dark)]' />
                <p className='md:text-lg text-sm text-gray-700'>24 hr Emergency Call Out</p>
            </a>
            </div>  
            {/* <button> Email us Today</button> */}
            
        </div>

    </div>
  )
}

export default TopBar