import React from 'react';
import forestsun from '../assets/forest-sun.jpg';
import forest from '../assets/forest.jpg';
import stumps from '../assets/stumps.jpg';
import treeremoval from '../assets/tree-removal.jpg';
import woodstack from '../assets/wood-stack.jpg';
import treesup from '../assets/trees-up.jpg';
import { Link } from 'react-router-dom';

const services = () => {
  return (
    <div className='mt-[-75px]'>
        <Link to="/services">
        <div className='max-w-[70%] m-auto w-full md:flex' id="Services">
        
            <div className='relative p-4 '>
                <h3 className='absolute z-10 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-white text-2xl font-bold'>Tree Surgery</h3>
                <img src={forestsun} alt='forest-sun' className="w-full h-full object-cover relative border-4 border-white shadow-lg"/>
            </div>
            
            <div className='relative p-4 '>
                <h3 className='absolute z-10 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-white text-2xl font-bold'>Clearance</h3>
                <img src={treesup} alt='trees-up' className="w-full h-full object-cover relative border-4 border-white shadow-lg"/>
            </div>
            
            <div className='relative p-4 '>
                <h3 className='absolute z-10 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-white text-2xl font-bold'>Stump Removal</h3>
                <img src={stumps} alt='stumps' className="w-full h-full object-cover relative border-4 border-white shadow-lg"/>
            </div>
        
        </div>
        </Link>
        <Link to="/services">
        <div className='max-w-[70%] m-auto w-full hidden md:flex mt-[20px] pb-[25px]'>
            <div className='relative p-4 '>
                <h3 className='absolute z-10 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-white text-2xl font-bold'>Hedging</h3>
                <img src={treeremoval} alt='tree-removal' className="w-full h-full object-cover relative border-4 border-white shadow-lg"/>
            </div>
            
            <div className='relative p-4 '>
                <h3 className='absolute z-10 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-white text-2xl font-bold'>Gardening</h3>
                <img src={forest} alt='forest' className="w-full h-full object-cover relative border-4 border-white shadow-lg"/>
            </div>
            
            <div className='relative p-4 '>
                <h3 className='absolute z-10 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-white text-2xl font-bold'>Fencing</h3>
                <img src={woodstack} alt='woodstack' className="w-full h-full object-cover relative border-4 border-white shadow-lg"/>
            </div>
            
        </div>
        </Link>
    </div>
  )
}

export default services