import React from 'react'
import chainsaw from '../assets/chainsaw-tree.jpg'

const Hero2 = () => {
    return (
    <div className='w-full'>
      <div className='relative w-full h-[100vh] md:h-[80vh]'>
          <img src ={chainsaw} alt="chainsaw" className='w-full h-full object-cover opacity-90 bg-blend-darken' />
  
          <div className=' absolute top-[10%] md:top-[30%] md: left-[15%] w-[75%] max-w-[1140px] m-auto'>
              <div className='w-full md:[90%] max-w-[700px] h-full flex flex-col text-white p-4'>
                  <h1 className='font-bold text-5xl'>Always Here to Help</h1>
                  <h2 className='text-4xl py-4 italic'>Local and Reliable</h2>
                  <p className='text-lg'>Providing fencing and tree work services for domestic, commercial, and agricultural customers throughout Wimborne, Ferndown, Ringwood, Poole & Surrounding areas for over 4 decades.</p>
              </div>
          </div>
        </div>
      </div>
    )
  }
  
  export default Hero2
  
