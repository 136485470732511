import React from 'react'
import uptree from '../assets/up-tree.jpg'

const testimonial = () => {
  return (
    <div className='w-full bg-white'>
      <div className='max-w-[80%] m-auto md:flex pt-[20px] pb-[30px]'>
          <div className='relative p-4 w-30 md:w-1/3 '>
              <img src={uptree} alt='up tree' className="h-full w-full object-cover relative border-4 border-white shadow-lg max-h-[400px]"/>
          </div>
          <div className='relative p-4 w-full md:w-2/3 max-w-[700px] pl-[20px]'>
              <h1 className='font-bold text-4xl'>A testimonial from one of our many happy customers: </h1>
              <h2 className='text-2xl py-4 italic'>"Excellent service and will definitely use them again!</h2>
              <p className='text-lg'>Mark and the team stepped in to undertake a job no other local team would do. They safely removed a tree and its large overhanging branches above a fragile shed that is home to my immovable classic car. <br></br><br></br> I can say not even a leaf fell on the shed and the job was done to a very high standard."</p>
          </div>
      </div>
    </div>

  )
}

export default testimonial