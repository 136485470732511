import React from 'react';
import TopBar from '../components/TopBar';
import NavBar from '../components/NavBar';
import Footer from '../components/footer';
import Gallery from '../components/galleryComp'
import ServiceList from '../components/serviceList';



function ServicePage() {
  return (
    <div >
      <TopBar />
      <NavBar />
      <ServiceList />
      <Gallery />
      <Footer />
    </div>
  );
}

export default ServicePage;