import React from 'react'
import image_0 from '../assets/image_0.jpg'
import image_2 from '../assets/image_2.jpg'
import image_3 from '../assets/image_3.jpg'
import image_4 from '../assets/image_4.jpg'
import image_6 from '../assets/image_6.jpg'
import image_7 from '../assets/image_7.jpg'
import image_8 from '../assets/image_8.jpg'
import image_9 from '../assets/image_9.jpg'
import image_10 from '../assets/image_10.jpg'
import image_11 from '../assets/image_11.jpg'


const Gallery = () => {
  return (
    <div id='gallery' className='max-w-[1140px] m-auto w-full px-4 py-16'>
        <h2 className='text-4xl pt-[20px] text-center text-gray-700 p-4'>Gallery</h2>
        <div className='grid sm:grid-cols-5 gap-4'>
            <div className='sm:col-span-3 col-span-2 row-span-2'>
                <img className='w-full h-full object-cover'src={image_0} alt="garden" />
            </div>
            <div>
                <img className='w-full h-full object-cover 'src={image_2} alt="main in green" />
            </div>
            <div>
                <img className='w-full h-full object-cover 'src={image_3} alt="patio" />
            </div>
            <div>
                <img className='w-full h-full object-cover 'src={image_4} alt="house" />
            </div>
            <div>
                <img className='w-full h-full object-cover 'src={image_7} alt="flare" />
            </div>
        </div>
        <div className='grid sm:grid-cols-5 gap-4 pt-[20px]'>
            <div className='sm:col-span-3 col-span-2 row-span-2 md:col-start-3 md:col-end-6 md:row-start-1'>
                <img className='w-full h-full object-cover'src={image_6} alt="digger" />
            </div>
            <div>
                <img className='w-full h-full object-cover 'src={image_8} alt="flowers in mepmphis" />
            </div>
            <div>
                <img className='w-full h-full object-cover 'src={image_9} alt="cahinsaw" />
            </div>
            <div>
                <img className='w-full h-full object-cover 'src={image_10} alt="tool" />
            </div>
            <div>
                <img className='w-full h-full object-cover 'src={image_11} alt="saw dust" />
            </div>
        </div>
    </div>
    
  )
}

export default Gallery