import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter,RouterProvider } from 'react-router-dom';
import ServicePage from './Pages/Services_page';
import Home from './Pages/Home';
import './index.css';

const router = createBrowserRouter([
  { path: '/', element: <Home /> },
  { path: '/services', element: <ServicePage /> },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);

