import logo from '../assets/logo-no-text.png' 
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className="w-full p-8 bg-[#0D2935]/100">
      <div className="flex flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 bg-[#0D2935]/100 text-center md:justify-between">
        <img src={logo} alt="logo-ct" className="w-[25%] md:w-[8%]" />
        <ul className="flex flex-wrap items-center gap-y-2 gap-x-8">
          <li>
          <Link to ="/" className="text-white font-normal transition-colors">Home</Link>
          </li>
          <li>
            <a href="tel:07810 876930" className="text-white font-normal transition-colors ">Contact Us</a>
          </li>
        </ul>
      </div>
      <hr className="my-4 border-[#0D2935]/100" />
      <a href="https://www.paiback.app" className="block text-white text-center font-normal">&copy; 2024 pAiback</a>
    </footer>
  );
}

export default Footer
