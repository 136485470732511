import React from 'react'
import logo from '../assets/logo-no-text.png'

const About = () => {
  return (
    <div className='w-full bg-gray-700' id='About'>
    <div className='max-w-[80%] md:max-w-[50%] m-auto text-white text-center'>
        <h1 className="text-4xl pt-[20px]">Meet the Team</h1>
    </div>

    <div className='max-w-[80%] md:max-w-[50%] m-auto md:flex pb-[20px] pt-[20px]'>
        <div className='relative p-4 w-30 block md:hidden lg:block '>
            <img src={logo} alt='mark' className=" h-[300px] lg:h-[80%] object-cover relative border-4 border-white shadow-lg"/>
        </div>
        
        <div className='relative p-4 w-70 text-white'>
            <p>With over 40 years of experience in the field of fencing and tree work, we undertake all kinds of jobs - none too big - none too small.<br></br><br></br>

            The family run team from the heart of Dorset has grown in to a group of more than 10 and we pride ourselves on our friendly and professional service.<br></br><br></br>
             
             Despite being family run, we have cut no corners as we are fully certified and comprehensibly insured, allowing us to regularly travel to travel to the surrounding counties to deliver our top notch service.</p>
            <p className="text-xl mt-4">Always here to help!</p>
        </div>
    </div>
    </div>



  )
}

export default About