import React from 'react';
import forestsun from '../assets/forest-sun.jpg';
import treesup from '../assets/trees-up.jpg';
import stumps from '../assets/stumps.jpg';
import treeremoval from '../assets/tree-removal.jpg';
import forest from '../assets/forest.jpg';
import woodstack from '../assets/wood-stack.jpg';

function serviceList() {
    return (
        <div className="container mx-auto px-4 md:px-0">
          {/* Grid wrapper for items */}
          <div className="md:flex md:flex-wrap md:-mx-4 justify-center pt-[20px]">
            {/* Item 1: Tree Surgery */}
            <div className="w-full md:w-1/3 md:px-4 mb-8 flex justify-center">
              <div className="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col items-center">
                <div className="p-4 text-center">
                  <h2 className="text-lg font-semibold mb-2">Tree Surgery</h2>
                  <p className="text-sm text-gray-700 mb-2">Expert Tree Care: Keeping Nature in Shape</p>
                </div>
                <img src={forestsun} alt="Tree Surgery" className="w-full h-auto max-h-[275px]" />
                <div className="p-4 text-center">
                  <p className="text-sm text-gray-600">We're your go-to for all things tree-related. Whether it's a trim, a touch-up, or a full-on rescue mission, we've got the skills to keep your trees looking their best. </p>
                </div>
              </div>
            </div>
            {/* Item 2: Garden Clearance */}
            <div className="w-full md:w-1/3 md:px-4 mb-8 flex justify-center">
              <div className="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col items-center">
                <div className="p-4 text-center">
                  <h2 className="text-lg font-semibold mb-2">Garden Clearance</h2>
                  <p className="text-sm text-gray-700 mb-2">Reclaiming Your Green Space: Garden Clearance Pros</p>
                </div>
                <img src={treesup} alt="Garden Clearance" className="w-full h-auto max-h-[275px]" />
                <div className="p-4 text-center">
                  <p className="text-sm text-gray-600">From tangled messes to overgrown chaos, we're here to clear the way and give your green space a fresh start. <br></br><br></br> Whether it's a small backyard or a huge estate - say goodbye to the weeds and hello to a garden you can actually enjoy.</p>
                </div>
              </div>
            </div>
            {/* Item 3: Stump Removal */}
            <div className="w-full md:w-1/3 md:px-4 mb-8 flex justify-center">
              <div className="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col items-center">
                <div className="p-4 text-center">
                  <h2 className="text-lg font-semibold mb-2">Stump Removal</h2>
                  <p className="text-sm text-gray-700 mb-2">Rooting Out the Past: Stump Removal Specialists</p>
                </div>
                <img src={stumps} alt="Stump Removal" className="w-full h-auto max-h-[275px]" />
                <div className="p-4 text-center">
                  <p className="text-sm text-gray-600"> With state-of-the-art equipment and a commitment to getting the job done right, we'll have that stump out of sight and out of mind in no time. </p>
                </div>
              </div>
            </div>
            {/* Repeat the above divs for each item */}
            {/* You can add more items such as Hedging, Gardening, and Fencing */}
          </div>
          <div className="md:flex md:flex-wrap md:-mx-4 justify-center">
            {/* Item 1: Tree Surgery */}
            <div className="w-full md:w-1/3 md:px-4 mb-8 flex justify-center">
              <div className="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col items-center">
                <div className="p-4 text-center">
                  <h2 className="text-lg font-semibold mb-2">Hedging</h2>
                  <p className="text-sm text-gray-700 mb-2">Expert hedge care: Crafting Green Boundaries</p>
                </div>
                <img src={treeremoval} alt="Tree removal" className="w-full h-auto max-h-[275px]" />
                <div className="p-4 text-center">
                  <p className="text-sm text-gray-600">When it comes to defining your outdoor space, nothing beats a well-kept hedge. Our expert team is here to sculpt, shape, and maintain your hedges, creating clean lines that enhance the beauty of your property.</p>
                </div>
              </div>
            </div>
            {/* Item 2: Garden Clearance */}
            <div className="w-full md:w-1/3 md:px-4 mb-8 flex justify-center">
              <div className="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col items-center">
                <div className="p-4 text-center">
                  <h2 className="text-lg font-semibold mb-2">Gardening</h2>
                  <p className="text-sm text-gray-700 mb-2">Garden mastery: Crafting Outdoor Retreats</p>
                </div>
                <img src={forest} alt="Garden Clearance" className="w-full h-auto max-h-[275px]" />
                <div className="p-4 text-center">
                  <p className="text-sm text-gray-600">Our gardening services are all about turning your outdoor space into a place you love to be. From planting and pruning to weeding and watering, we've got your back. </p>
                </div>
              </div>
            </div>
            {/* Item 3: Stump Removal */}
            <div className="w-full md:w-1/3 md:px-4 mb-8 flex justify-center">
              <div className="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col items-center">
                <div className="p-4 text-center">
                  <h2 className="text-lg font-semibold mb-2">Fencing</h2>
                  <p className="text-sm text-gray-700 mb-2">Securing Boundaries: Elegant designs</p>
                </div>
                <img src={woodstack} alt="Stump Removal" className="w-full h-auto max-h-[275px]" />
                <div className="p-4 text-center">
                  <p className="text-sm text-gray-600"> Our agricultural fencing services provide farmers with reliable protection for crops and livestock, ensuring a secure and productive farming environment.  <br></br><br></br> From traditional wooden fences to modern electric options, we offer a wide range of materials and styles. 
                </p>
                </div>
              </div>
            </div>
            {/* Repeat the above divs for each item */}
            {/* You can add more items such as Hedging, Gardening, and Fencing */}
          </div>
        </div>
      );
    }

export default serviceList;
