import React from 'react'
import TreeCutting from '../assets/tree-cutting.jpg'

const Hero = () => {
  return (
    <div className='w-full h-[100vh] md:h-[80vh]'>
      <img src ={TreeCutting} alt="Tree Cutting" className='w-full h-full object-cover opacity-90 bg-blend-darken' />

    <div className='max-w-[1140px] m-auto'>
      <div className='absolute top-[30%] md:top-[40%] w-full md:+[50%] max-w-[700px] h-full flex flex-col text-white p-4'>
      <h1 className='font-bold text-5xl'>Ark Fencing and Tree Work</h1>
      <h2 className='text-4xl py-4 italic'>Professional Tree Cutting Services</h2>
      <p className='text-lg'>Discover exceptional service for fencing needs. Family-run, we excel in close client collaboration, offering diverse fencing solutions, emergency services, and expertise in tree surgery using drones. Trust us for tailored, top-quality results.</p>
      </div>
    </div>
    </div>
  )
}

export default Hero