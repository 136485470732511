import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {FaBars} from 'react-icons/fa'
import {AiFillPhone} from 'react-icons/ai'
// import { FaFacebookF, FaTwitter, FaGooglePlusG, FaInstagram } from 'react-icons/fa'


const NavBar = () => {
const [nav,setNav] =useState(false)
const handleNav = () => {
    setNav(!nav)
}
const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const scrollThreshold = windowHeight * 0.1; // 10% of the window height

      if (scrollPosition > scrollThreshold) {
        setIsHidden(true);
      } else {
        setIsHidden(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className='w-full min-h-[50px] flex justify-between items-center absolute z-10 text-white bg-[#0D2935] sticky top-0 z-50'>
        <ul className='hidden sm:flex px-4'>
            <li>
              <Link to ="/" className="text-lg">Home</Link>
            </li>
            <li>
              <Link to="/services" className="text-lg">Services</Link>
            </li>
            <li>
              <Link to="/services#gallery" className="text-lg">Gallery</Link>
            </li>
        </ul>
        <div className='flex justify-between w-[40%] md:w-[10%] '>
            {/* <FaFacebookF size={20} className='mx-4 '/>
            <FaTwitter size={20} className='mx-4 '/>
            <FaGooglePlusG size={20} className='mx-4 '/>
            <FaInstagram size={20} className='mx-4 '/> */}
            <a href="tel:07810 876930" className={isHidden ? "flex items-center px-[5%]": "hidden"}>
                <AiFillPhone size="20" className="mr-2 text-white" />
                <p class="md:block md:text-lg text-sm text-white">07810 876930</p>
            </a>
        </div>
        {/*Hamburger Icon */}
        <div  onClick={handleNav} className='sm:hidden z-10'>
            <FaBars size={20} className='mr-4 cursor-pointer'/>
        </div>
        {/*movile menu */}
        <div onClick={handleNav} className={nav ?'overflow-y-hidden md:hidden ease-in duration-300 absolute text-gray-300 left-0 top-0 w-full h-screen bg-black/90 px-4 py-7 flex flex-col':'absolute top-0 h-screen left-[-100%] ease-in duration-500'}>
            <ul className='h-full w-full text-center pt-12'>
                <li className='text 2xl py-8'>
                <Link to ="/" className="text-lg">Home</Link>
                </li>
                <li className='text 2xl py-8'>
                <Link to="/services" className="text-lg">Services</Link>
                </li>
                <li className='text 2xl py-8'>
                <Link to="/services#gallery" className="text-lg">Gallery</Link>
                </li>
            </ul>
        </div>
    </div>
  )
}

export default NavBar